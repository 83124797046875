import { useState } from "react"
import "./portfolio.scss"

export default function Portfolio() {
    const [selected,setSelected] = useState("Engineering")

            const list = [
            {
                id: "Engineering",
                title: "Engineering"
            },
                
            {
                id: "Project Management",
                title: "Project Management"
            },
            
            {
                id: "Guitars",
                title: "Guitars"
            },
            ]
    return (
        <div className="portfolio" id="portfolio">

            <h1>Portfolio</h1>
            <ul>
                {list.map((item) => (
                    <portfoliolist
                        title={item.title}
                        active={selected === item.id}
                        setSelected={setSelected}
                    />
                ))}
                <li classNam="active">Engineering</li>
                <li>Project Management</li>
                <li>Guitars</li>
            </ul>
            <div className="container">
                <div className="item">
                    <img src="../assets/project.jpg"
                    alt=""
                    />
                    <h3>Overseas Project</h3>
                </div>
            </div>

            <div className="item">
                <img src=""
                    alt=""
                />
                <h3>Overseas Project</h3>
            </div>
        </div>
    )
}
